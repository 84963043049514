import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function AutomationGuide() {
  return (
    <Layout
      keywords="IrisAgent Automation guide, Intercom Automation documentation"
      title="Set up Automations and Workflows on your IrisAgent account"
      description="Documentation on how to set up automations and workflows on your IrisAgent account to automate your mundane support tasks with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/automation-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisAgent Automation Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Automation Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Automation Guide</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    Tagging tickets is manual, mundane, and error-prone. Tags get obsolete and incorrect tagging results in inaccurate responses and analytics. IrisAgent solves this by AI-driven automated, and self-learning tag management. No manual effort is needed.
                    <br/><br/>
                    Agents and supervisors spend manual effort in triaging and resolving issues that may already be known or solved previously. IrisAgent solves this by automated workflows based on AI-powered tagging, AI-based answers to known issues, and configurable actions for ticket assignment and notifications.
                    <br/>
                    <br/>
                    <h2>
                    Set up Automated Tagging
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    If your ticketing platform already has the tags or custom fields you'd like to automate, please let us know by <a href="mailto:contact@irisagent.com?subject=Existing tags to automate" target="_blank">sending us an email</a>. Include the names of these tags or custom fields in your message. We will use tickets containing these tags to train our machine learning model. If there aren't enough tickets, please proceed to step 2 below.
                    </li>
                    <li>
                    Alternatively, if you need to add new tags that aren't covered in step 1, navigate to the <b>Automations -> Categories</b> page on the <a href="https://web.irisagent.com/automation/categories" target="_blank">IrisAgent dashboard</a> and click on the <b>+ Add ML Category</b> button on the right.
                    <img
                src="/img/automation-category.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Enter the name, short description, and a couple of examples for the ML Category/Categories you want to automate. Note that the Category name should match the name of an already existing tag in your ticketing system if you'd like us to write the Category in your ticketing system. Click on <b>Create</b><br/>
                    <img
                src="/img/add-ml-category.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ol>
                    That's all! Give our data scientists one business day to set up the ML models and start automating your tagging.
                    <br/><br/>
                    <h2>
                    Set up Automation Workflows
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Go to the <b>Automations -> Triggers</b> page on the <a href="https://web.irisagent.com/triggers" target="_blank">IrisAgent dashboard</a> and click on the <b>+ Create New Trigger</b> button on the top-right.
                    </li>
                    <li>
                    Select any number of <b>Conditions</b> and <b>Actions</b> to be included in your trigger automation. </li>
                    <li>Give your trigger a name by clicking on the pencil icon. </li>
                    <li>Click on the <b>Add New Trigger</b> button to save the trigger.
                    </li>
                    </ol>

<br/><br/>

Feel free to <a href="mailto:contact@irisagent.com" target="_blank">email us</a> if you encounter any issues or require assistance with product onboarding.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
